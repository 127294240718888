<template>
  <ul>
    <menu-options-item close label="Abrir" @click="$emit('abrir')" />
    <menu-options-item close label="Excluir" @click="$emit('excluir')"  label-class="text-negative" />
    <div class="diviser"></div>
    <menu-options-item label="Recibo"  icon="file-pdf" disabled @click="$emit('recibo')" />
    <menu-options-item label="Baixar Fatura" icon="file-pdf" disabled @click="$emit('fatura-pdf')" />
    <menu-options-item v-if="m.tipo === 1" label="Cobrar" icon="envelope" disabled @click="$emit('fatura-pdf')" />
  </ul>
</template>

<script>
import MenuOptionsItem from '../../../layout/context-menu/context-window-options-item'
export default {
  name: 'list-menu',
  props: ['m'],
  components: {MenuOptionsItem}
}
</script>
